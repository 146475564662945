import type { Redirect } from '@prisma/client';

export default defineNuxtRouteMiddleware(async (to) => {
    const { data: redirects } = await useFetch<Redirect[]>('/api/redirects');

    if (!redirects.value) {
        return;
    }

    const currentPath = to.path.replace(/^\/|\/$/g, '');
    const redirect = redirects.value.find(
        (r: Redirect) => r.from === currentPath,
    );

    if (redirect) {
        return navigateTo(redirect.to, {
            redirectCode: redirect.code,
            external: true,
        });
    }
});
