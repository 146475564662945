<script setup lang="ts">
import ModalDialog from '~/components/ui/ModalDialog.vue';
import { useConfirmDialog } from '~/utils/client/useConfirmDialog';

const state = useConfirmDialog();

const onConfirm = (close: () => void) => {
    state.resolve(true);
    close();
};

const onReject = (close: () => void) => {
    state.resolve(false);
    close();
};
</script>

<template>
    <div>
        <ModalDialog v-model:open="state.open">
            <template #dialog="{ close }">
                <div class="flex flex-row justify-between">
                    <h1 v-if="state.title" class="text-2xl font-bold">
                        {{ state.title }}
                    </h1>
                </div>
                <div class="py-10">
                    {{ state.message }}
                </div>
                <div class="flex flex-row justify-between">
                    <button class="btn btn-error" @click="onReject(close)">
                        Zrušit
                    </button>
                    <button class="btn btn-success" @click="onConfirm(close)">
                        Potvrdit
                    </button>
                </div>
            </template>
        </ModalDialog>
    </div>
</template>
