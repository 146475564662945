export enum AlertTypes {
    Success = 'alert-success',
    Error = 'alert-error',
    Info = 'alert-info',
    Warning = 'alert-warning',
}

export type ToastType = {
    type: AlertTypes;
    icon: string;
    message: string;
    timeout: number | null;
};

export const toasts: ToastType[] = reactive([]);

export const toast = (
    type: AlertTypes,
    message: string,
    icon?: string,
    timeout: number | null = 5,
): void => {
    const toast: ToastType = {
        type,
        icon: icon || '',
        message,
        timeout,
    };

    toasts.push(toast);

    if (timeout !== null) {
        setTimeout(() => {
            toasts.splice(toasts.indexOf(toast), 1);
        }, timeout * 1000);
    }
};

export const info = (message: string, timeout: number | null = 5): void => {
    toast(AlertTypes.Info, message, 'InformationCircleIcon', timeout);
};

export const success = (message: string, timeout: number | null = 5): void => {
    toast(AlertTypes.Success, message, 'CheckCircleIcon', timeout);
};

export const error = (message: string, timeout: number | null = 5): void => {
    toast(AlertTypes.Error, message, 'XCircleIcon', timeout);
};

export const warning = (message: string, timeout: number | null = 5): void => {
    toast(AlertTypes.Warning, message, 'ExclamationTriangleIcon', timeout);
};
