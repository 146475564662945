export const grantConsent = async () => {
    const { gtag } = useGtag();
    const nuxtApp = useNuxtApp();

    gtag('consent', 'update', {
        ad_storage: 'granted',
        ad_user_data: 'granted',
        ad_personalization: 'granted',
        analytics_storage: 'granted',
        personalization_storage: 'granted',
    });

    nuxtApp.$fb.enable();
};

export const revokeConsent = async () => {
    const { gtag } = useGtag();
    const nuxtApp = useNuxtApp();

    gtag('consent', 'update', {
        ad_storage: 'denied',
        ad_user_data: 'denied',
        ad_personalization: 'denied',
        analytics_storage: 'denied',
        personalization_storage: 'denied',
    });

    nuxtApp.$fb.disable();
};
