export type VocabularyItem = {
    slug: string;
    title: string;
    inflexion?: string;
    tooltip?: string;
    content: string;
};

const getTooltip = (item: VocabularyItem) => {
    const tooltip = `<span onclick="onClickShowPopup(this)" onmouseenter="showPopup(this)" onmouseleave="hidePopup(this)" class="border-secondary border-dashed border-b-2">$2</span>
        <span id="tooltip-${item.slug}" popover class="mobile-tooltip" >${item.tooltip}</span>`;

    return `$1${tooltip}$3`;
};

const inflexionToolTipDelimiter = '%%!!%%';

export const correctTooltipsPosition = () => {
    if (!import.meta.client) {
        return;
    }

    for (const tooltip of document.querySelectorAll('.auto-tooltip')) {
        const parentElement =
            tooltip.closest('.collapse-content') ?? tooltip.closest('.prose');
        if (!parentElement) {
            continue;
        }

        if (
            tooltip.getBoundingClientRect().left <
            parentElement.getBoundingClientRect().left + 100
        ) {
            tooltip.classList.add('tooltip-right');
        } else if (
            tooltip.getBoundingClientRect().right >
            parentElement.getBoundingClientRect().right - 100
        ) {
            tooltip.classList.add('tooltip-left');
        } else if (
            tooltip.getBoundingClientRect().top <
            parentElement.getBoundingClientRect().top + 100
        ) {
            tooltip.classList.add('tooltip-bottom');
        } else if (
            tooltip.getBoundingClientRect().bottom >
            parentElement.getBoundingClientRect().bottom - 100
        ) {
            tooltip.classList.add('tooltip-top');
        }
    }
};

export const useTooltips = (text: string, vocabulary: VocabularyItem[]) => {
    let result = text;

    for (const item of vocabulary) {
        if (!item.inflexion || !item.tooltip) {
            continue;
        }
        const pattern = new RegExp(
            `( |"|^|>)(${item.inflexion})( |"|$|<)`,
            'gi',
        );
        result = result.replaceAll(
            pattern,
            `$1${inflexionToolTipDelimiter}$2${inflexionToolTipDelimiter}$3`,
        );
    }

    for (const item of vocabulary) {
        if (!item.inflexion || !item.tooltip) {
            continue;
        }
        const pattern = new RegExp(
            `( |"|^|>)${inflexionToolTipDelimiter}(${item.inflexion})${inflexionToolTipDelimiter}( |"|$|<)`,
            'gi',
        );
        result = result.replaceAll(pattern, getTooltip(item));
    }

    return result;
};
