import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin({
    name: 'sentry',
    parallel: true,
    async setup(nuxtApp) {
        const config = useRuntimeConfig();
        if (!import.meta.client || !config.public.sentry?.dsn) {
            return;
        }

        const vueApp = nuxtApp.vueApp;

        Sentry.init({
            app: vueApp,
            dsn: config.public.sentry.dsn,
            environment: config.public.sentry.environment,
            integrations: [
                Sentry.replayIntegration({
                    maskAllText: false,
                    blockAllMedia: false,
                }),
                Sentry.browserTracingIntegration(),
            ],
            tracesSampleRate: 1.0,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        });
    },
});
