<script setup lang="ts">
import * as OutlineIcons from '@heroicons/vue/24/outline';
import * as SolidIcons from '@heroicons/vue/24/solid';

const props = withDefaults(
    defineProps<{
        name: string;
        solid?: boolean;
        size?: number;
    }>(),
    {
        solid: false,
        size: 24,
    },
);

const iconComponent = computed(() => {
    const icons: SolidIcons | OutlineIcons = props.solid
        ? SolidIcons
        : OutlineIcons;
    const icon = icons[props.name];

    if (!icon) {
        throw new Error(`Icon ${props.name} not found`);
    }

    return icon;
});
</script>

<template>
    <component
        :is="iconComponent"
        :class="`daisy-hero-icon w-${size} h-${size}`"
    />
</template>

<style scoped lang="scss">
    .daisy-hero-icon {
        display: inline-block;
        vertical-align: top;
    }
</style>
