<script setup lang="ts">
const open = defineModel<boolean>('open');

watch(
    () => open.value,
    (value) => {
        if (value) {
            modal.value?.showModal();
        } else {
            modal.value?.close();
        }
    },
);

withDefaults(
    defineProps<{
        classList?: string;
    }>(),
    {
        classList: '',
    },
);

const modal = ref<HTMLDialogElement | null>(null);
const openDialog = () => {
    open.value = true;
};
const closeDialog = () => {
    open.value = false;
};
</script>

<template>
    <div>
        <slot :open="openDialog" />
        <dialog ref="modal" class="modal" @close="closeDialog">
            <div class="modal-box" :class="classList">
                <slot name="dialog" :close="closeDialog" />
            </div>
        </dialog>
    </div>
</template>
