<script setup lang="ts">
import HeroIcon from '~/components/ui/HeroIcon.vue';
import type { ToastType } from '~/utils/client/useToast';

defineProps<{
    toasts: ToastType[];
}>();
</script>

<template>
    <div class="toast z-[100]">
        <div
            v-for="({type, icon, message}, index) in toasts"
            :key="`${index}-${message}`"
            class="alert"
            :class="type"
        >
            <span><HeroIcon :name="icon" :size="6" /> {{ message }}</span>
        </div>
    </div>
</template>
