import type { Text } from '@prisma/client';
import { useTooltips } from '~/utils/client/useTooltips';
import type { VocabularyItem } from '~/utils/client/useTooltips';

const getTexts = async (): Promise<Text[]> => {
    const { data } = await useFetch<Text[]>('/api/texts');
    return data.value ?? [];
};

export const useTexts = async (
    vocabulary: VocabularyItem[] = [],
): Promise<(slug: string, useTooltip?: boolean) => string> => {
    const texts = useState<Text[]>('texts', () => []);
    texts.value = await getTexts();

    return (slug, useTooltip = false) => {
        const text =
            texts.value.find((text) => text.slug === slug)?.content ?? '';
        return useTooltip && vocabulary.length > 0
            ? useTooltips(text, vocabulary)
            : text;
    };
};
