type ConfirmDialogState = {
    open: boolean;
    title: string;
    message: string;
    resolve: (value: boolean) => void;
};

const confirmDialogDefaultState: ConfirmDialogState = {
    open: false,
    title: '',
    message: '',
    resolve: () => {},
};

const confirmDialogState = reactive({ ...confirmDialogDefaultState });

export const useConfirmDialog = () => confirmDialogState;

export const confirmDialog = (message: string, title?: string) => {
    confirmDialogState.open = true;
    confirmDialogState.message = message;
    confirmDialogState.title = title || 'Potvrzení';
    return new Promise((resolve) => {
        confirmDialogState.resolve = (value: boolean) => {
            Object.assign(confirmDialogState, confirmDialogDefaultState);
            resolve(value);
        };
    });
};
