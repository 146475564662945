
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta } from "/app/pages/index.vue?macro=true";
import { default as kontaktZnpy40LDWxDvLCIaJpWZIY_45EOYZubgQiFqL2fBVRV4kMeta } from "/app/pages/kontakt.vue?macro=true";
import { default as _91_46_46_46slug_93QkkyEDg6xOGuFkoxzFC_4565xuuMFULoZbO_183vt2UD8Meta } from "/app/pages/[...slug].vue?macro=true";
import { default as networkingz5YXPUd_D_45eUk8XNviwGUFebRXM5m3UIrjzSOlY4dzgMeta } from "/app/pages/networking.vue?macro=true";
import { default as indexUTPWuOuTEHiifiH2gSDbviVYzY2wHbmPaccb9r89Io4Meta } from "/app/pages/admin/index.vue?macro=true";
import { default as loginORwvU__450lx7h_a9Mz6vtkVrJAAfhRAYGj98sVs_awdkMeta } from "/app/pages/admin/login.vue?macro=true";
import { default as _91_46_46_46slug_93DyJA96rzgxfedZ4HEhg7HDMlrN2w0ydQ_45E5L6m8JyCcMeta } from "/app/pages/blog/[...slug].vue?macro=true";
import { default as indexDGBIUS1JlkGxmdP_45P9y1CHBS3vsMjS425W94_45SJzqawMeta } from "/app/pages/admin/faq/index.vue?macro=true";
import { default as indexSHK6EEA57ujd3Ja0htWVMiyaA_45hxDGyoeNyhp_456CDboMeta } from "/app/pages/admin/blog/index.vue?macro=true";
import { default as meeWs8y9qJvI_45pVgQzym9d36ipADqQ1ZjloGn4safq2pgMeta } from "/app/pages/admin/catalog/me.vue?macro=true";
import { default as indexFACMocgF5DubJUHoL29eHRdMQtmOGHp_45sgkdHQegGr8Meta } from "/app/pages/admin/tags/index.vue?macro=true";
import { default as _91_46_46_46slug_93Yt26TXHlxhKiVuhoYqw_45L3zRnLw40Nd2ZE9DkbtubIQMeta } from "/app/pages/zdarma/[...slug].vue?macro=true";
import { default as indexNZwehIV1yWk6lWo3iVNrf95B_45vuD2bjAnwm1poy_wn0Meta } from "/app/pages/admin/pages/index.vue?macro=true";
import { default as indexGbwrrreBS04uiBPXX8zNKlVqxrdyQ_45q9Gebhb_sveCgMeta } from "/app/pages/admin/texts/index.vue?macro=true";
import { default as indexgvs7WdV1NLBsiIM1p5V4txUPTMYikQ1vfsYYESmgxtgMeta } from "/app/pages/admin/users/index.vue?macro=true";
import { default as _91_46_46_46slug_930jHqfGCEkIv_VTi0C2zWkICM6c4rDZl4DzmBemlAdAsMeta } from "/app/pages/katalog/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_930rj2ZTfSLw4R9ZJmYcny5X4Uk0FGzyHvjT9aSCj_Y8sMeta } from "/app/pages/slovnik/[...slug].vue?macro=true";
import { default as unauthorized5E6yw8tANx__mUKAUOCsm31u2RBnl5caLZBulX8SAMIMeta } from "/app/pages/admin/unauthorized.vue?macro=true";
import { default as verify_45emaili6hApPSe8_uKgsp4bVuKY5vnWCqDhBGLE6dfQuBGwmQMeta } from "/app/pages/admin/verify-email.vue?macro=true";
import { default as indexPb7bZz3RlhdK6Aho6Rhh0McTiwGqRpcnIkxM3pIh1roMeta } from "/app/pages/admin/zdarma/index.vue?macro=true";
import { default as index4cGLXGLT3DFYWP0srapcsOIySDXhT2DosEUDUhtdS8UMeta } from "/app/pages/admin/catalog/index.vue?macro=true";
import { default as indexIc_45wA_45ANN9njAOQVW1whbdGX1vAWcFRKy6tjeOUlNKYMeta } from "/app/pages/admin/redirects/index.vue?macro=true";
import { default as registrace_45asistentek5CCiTxsj2spuufwRpJJDeqR2DnbOE__mH2cViX7HjoAMeta } from "/app/pages/registrace-asistentek.vue?macro=true";
import { default as index2_Tq6tTvfm8rNGAzMyb8I7XVP30qxHpxeo0rCYaKvlQMeta } from "/app/pages/admin/references/index.vue?macro=true";
import { default as indexyvZfsZQ7NSNuxapM3CDUlbJox_4516C9KTw9J1DaheYfsMeta } from "/app/pages/admin/vocabulary/index.vue?macro=true";
import { default as change_45logCETQ3flc3iskajbyHkTqC876zsa1nTgvpP7mrxQSDn8Meta } from "/app/pages/admin/catalog/change-log.vue?macro=true";
import { default as _91_46_46_46slug_93dbPLWbSfc_4568cilrBVP58E__XKfgKdsGN8iwjEV2i5cMeta } from "/app/pages/nabizene-sluzby/[...slug].vue?macro=true";
import { default as indexg_45wdvcIgLtiez2t_4579ckgbOObtSQPp8ncPSS0pQ_JlAMeta } from "/app/pages/admin/catalog-references/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "kontakt",
    path: "/kontakt",
    component: () => import("/app/pages/kontakt.vue")
  },
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/app/pages/[...slug].vue")
  },
  {
    name: "networking",
    path: "/networking",
    component: () => import("/app/pages/networking.vue")
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexUTPWuOuTEHiifiH2gSDbviVYzY2wHbmPaccb9r89Io4Meta || {},
    component: () => import("/app/pages/admin/index.vue")
  },
  {
    name: "admin-login",
    path: "/admin/login",
    meta: loginORwvU__450lx7h_a9Mz6vtkVrJAAfhRAYGj98sVs_awdkMeta || {},
    component: () => import("/app/pages/admin/login.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug(.*)*",
    component: () => import("/app/pages/blog/[...slug].vue")
  },
  {
    name: "admin-faq",
    path: "/admin/faq",
    meta: indexDGBIUS1JlkGxmdP_45P9y1CHBS3vsMjS425W94_45SJzqawMeta || {},
    component: () => import("/app/pages/admin/faq/index.vue")
  },
  {
    name: "admin-blog",
    path: "/admin/blog",
    meta: indexSHK6EEA57ujd3Ja0htWVMiyaA_45hxDGyoeNyhp_456CDboMeta || {},
    component: () => import("/app/pages/admin/blog/index.vue")
  },
  {
    name: "admin-catalog-me",
    path: "/admin/catalog/me",
    meta: meeWs8y9qJvI_45pVgQzym9d36ipADqQ1ZjloGn4safq2pgMeta || {},
    component: () => import("/app/pages/admin/catalog/me.vue")
  },
  {
    name: "admin-tags",
    path: "/admin/tags",
    meta: indexFACMocgF5DubJUHoL29eHRdMQtmOGHp_45sgkdHQegGr8Meta || {},
    component: () => import("/app/pages/admin/tags/index.vue")
  },
  {
    name: "zdarma-slug",
    path: "/zdarma/:slug(.*)*",
    component: () => import("/app/pages/zdarma/[...slug].vue")
  },
  {
    name: "admin-pages",
    path: "/admin/pages",
    meta: indexNZwehIV1yWk6lWo3iVNrf95B_45vuD2bjAnwm1poy_wn0Meta || {},
    component: () => import("/app/pages/admin/pages/index.vue")
  },
  {
    name: "admin-texts",
    path: "/admin/texts",
    meta: indexGbwrrreBS04uiBPXX8zNKlVqxrdyQ_45q9Gebhb_sveCgMeta || {},
    component: () => import("/app/pages/admin/texts/index.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    meta: indexgvs7WdV1NLBsiIM1p5V4txUPTMYikQ1vfsYYESmgxtgMeta || {},
    component: () => import("/app/pages/admin/users/index.vue")
  },
  {
    name: "katalog-slug",
    path: "/katalog/:slug(.*)*",
    component: () => import("/app/pages/katalog/[...slug].vue")
  },
  {
    name: "slovnik-slug",
    path: "/slovnik/:slug(.*)*",
    component: () => import("/app/pages/slovnik/[...slug].vue")
  },
  {
    name: "admin-unauthorized",
    path: "/admin/unauthorized",
    meta: unauthorized5E6yw8tANx__mUKAUOCsm31u2RBnl5caLZBulX8SAMIMeta || {},
    component: () => import("/app/pages/admin/unauthorized.vue")
  },
  {
    name: "admin-verify-email",
    path: "/admin/verify-email",
    meta: verify_45emaili6hApPSe8_uKgsp4bVuKY5vnWCqDhBGLE6dfQuBGwmQMeta || {},
    component: () => import("/app/pages/admin/verify-email.vue")
  },
  {
    name: "admin-zdarma",
    path: "/admin/zdarma",
    meta: indexPb7bZz3RlhdK6Aho6Rhh0McTiwGqRpcnIkxM3pIh1roMeta || {},
    component: () => import("/app/pages/admin/zdarma/index.vue")
  },
  {
    name: "admin-catalog",
    path: "/admin/catalog",
    meta: index4cGLXGLT3DFYWP0srapcsOIySDXhT2DosEUDUhtdS8UMeta || {},
    component: () => import("/app/pages/admin/catalog/index.vue")
  },
  {
    name: "admin-redirects",
    path: "/admin/redirects",
    meta: indexIc_45wA_45ANN9njAOQVW1whbdGX1vAWcFRKy6tjeOUlNKYMeta || {},
    component: () => import("/app/pages/admin/redirects/index.vue")
  },
  {
    name: "registrace-asistentek",
    path: "/registrace-asistentek",
    component: () => import("/app/pages/registrace-asistentek.vue")
  },
  {
    name: "admin-references",
    path: "/admin/references",
    meta: index2_Tq6tTvfm8rNGAzMyb8I7XVP30qxHpxeo0rCYaKvlQMeta || {},
    component: () => import("/app/pages/admin/references/index.vue")
  },
  {
    name: "admin-vocabulary",
    path: "/admin/vocabulary",
    meta: indexyvZfsZQ7NSNuxapM3CDUlbJox_4516C9KTw9J1DaheYfsMeta || {},
    component: () => import("/app/pages/admin/vocabulary/index.vue")
  },
  {
    name: "admin-catalog-change-log",
    path: "/admin/catalog/change-log",
    meta: change_45logCETQ3flc3iskajbyHkTqC876zsa1nTgvpP7mrxQSDn8Meta || {},
    component: () => import("/app/pages/admin/catalog/change-log.vue")
  },
  {
    name: "nabizene-sluzby-slug",
    path: "/nabizene-sluzby/:slug(.*)*",
    component: () => import("/app/pages/nabizene-sluzby/[...slug].vue")
  },
  {
    name: "admin-catalog-references",
    path: "/admin/catalog-references",
    meta: indexg_45wdvcIgLtiez2t_4579ckgbOObtSQPp8ncPSS0pQ_JlAMeta || {},
    component: () => import("/app/pages/admin/catalog-references/index.vue")
  }
]