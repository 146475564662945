<script setup lang="ts">
import ConfirmModalDialog from '~/components/ui/ConfirmModalDialog.vue';
import NotificationToast from '~/components/ui/NotificationToast.vue';
import type { HTMLElementNext } from '~/types/HTMLElementNext';
import { grantConsent } from '~/utils/client/useGtagConsent';
import { useTexts } from '~/utils/client/useTexts';
import { toasts } from '~/utils/client/useToast';

const cookiesAccepted = useCookie<-1 | 0 | 1>('cookies');
if (cookiesAccepted.value > 0) {
    grantConsent();
}

const route = useRoute();
const layout = computed(() =>
    route.path.startsWith('/admin') ? 'admin' : 'default',
);

const useText = await useTexts();

useSeoMeta({
    description: useText('seo-popis'),
    ogTitle: 'Najdi asistentku!',
    ogDescription: useText('seo-popis-kratky'),
    ogUrl: 'https://najdiasistentku.cz',
    twitterTitle: 'Najdi asistentku!',
    twitterDescription: useText('seo-popis-kratky'),
    twitterCard: 'summary',
});

defineOgImageComponent('Default', {
    image: '/logo_og.png',
    domain: 'https://najdiasistentku.cz',
});

useHead({
    titleTemplate: (title?: string): string => {
        return title ? `${title} | Najdi asistentku!` : 'Najdi asistentku!';
    },
    htmlAttrs: {
        lang: 'cs',
    },
    link: [
        {
            rel: 'icon',
            type: 'image/png',
            href: '/favicon.png',
        },
    ],
    meta: [
        {
            name: 'facebook-domain-verification',
            content: 'hepiufb9spaxhizhop16xnl62filt4',
        },
    ],
    script: [
        {
            key: 'gtm',
            src: '/googletagmanager.js',
            type: 'text/javascript',
        },
    ],
});

if (import.meta.client) {
    const setTooltipPositions = (span: HTMLElementNext) => {
        const { right, left, top, width, height } =
            span.getBoundingClientRect();

        const style: { [key: string]: string } = {
            top: `${top + height / 2}px`,
            left: 'auto',
            right: 'auto',
        };
        if (left < window.innerWidth / 2) {
            style.left = `${left + width}px`;
        } else {
            style.right = `${window.innerWidth - right + width}px`;
        }
        span.nextElementSibling?.style.setProperty('top', style.top);
        span.nextElementSibling?.style.setProperty('left', style.left);
        span.nextElementSibling?.style.setProperty('right', style.right);
    };

    window.onClickShowPopup = (span: HTMLElementNext) => {
        span.nextElementSibling?.togglePopover();
    };

    window.showPopup = (span: HTMLElementNext) => {
        if (window.innerWidth < 1024) {
            return;
        }

        setTooltipPositions(span);
        span.dataset.intervalId = setInterval(() => {
            setTooltipPositions(span);
        }, 200).toString();

        span.nextElementSibling?.togglePopover();
    };

    window.hidePopup = (span: HTMLElementNext) => {
        if (window.innerWidth < 1024) {
            return;
        }

        if (span?.dataset?.intervalId) {
            clearInterval(Number.parseInt(span.dataset.intervalId));
        }

        span.nextElementSibling?.togglePopover();
    };
}
</script>

<template>
    <!-- Google Tag Manager (noscript) -->
    <noscript>
        <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-PRX6N3KR"
            height="0" width="0" style="display:none;visibility:hidden"
        ></iframe>
    </noscript>
    <!-- End Google Tag Manager (noscript) -->

    <NuxtLayout :name="layout">
        <NuxtPage />
        <ConfirmModalDialog />
        <NotificationToast :toasts="toasts" />
    </NuxtLayout>
</template>

<style lang="postcss">
:root {
    box-sizing: border-box;
}

*, *::before, *::after {
    box-sizing: inherit;
}

html {
    min-height: 100%;
}

body, #__nuxt {
    height: 100%;
    overflow-x: hidden;
}

.prose {
    ul, ol {
        padding-left: 2rem;
        li {
            p {
                margin: 0;
            }
        }
    }
}

.mobile-tooltip {
    max-width: 20rem;
    border-radius: 0.25rem;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    background-color: oklch(0.235742 0.066235 313.19);
    color: #fff;
    width: max-content;
    text-align: center;

    @media (min-width: 1024px) {
        position: fixed;
        margin: 0 10px;
        transform: translate(0px, -50%);
        left: auto;
        top: auto;
        right: auto;
    }
}

.mobile-tooltip::backdrop {
    background-color: rgb(0, 0, 0, 0.1);
    @media (min-width: 1024px) {
        background: none;
    }
}
</style>
